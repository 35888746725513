import React from 'react';
import { Link } from "gatsby"
import "../../styles/sell-catalog.css"
import LandingLogo from "../../images/landing/landing-logo.png"
import WhyImages1 from "../../images/sell-landing/why-images1.png"
import WhyImages2 from "../../images/sell-landing/why-images2.png"
import WhyImages3 from "../../images/sell-landing/why-images3.png"
import WhyImages4 from "../../images/sell-landing/why-images4.png"
import ArrowBtn from "../../images/sell-landing/arrow-btn.png"
import Delevery from "../../images/sell-landing/delivery.png"
import Syrienge from "../../images/sell-landing/syringe.png"
import sellInstagram from "../../images/sell-landing/sellinstagram.png"
import sellFacebook from "../../images/sell-landing/sellfacebook.png"
import sellLinkedin from "../../images/sell-landing/selllinkedin.png"
import sellYoutube from "../../images/sell-landing/sellyoutube.png"
import Rating from "../../images/sell-landing/stars.png"
import GooglePlay from "../../images/sell-landing/googleplay.png"
import phone from "../../images/sell-landing/phone.png"
import whitephoneicon from "../../images/sell-landing/white-phone-icon.png"
import VideoPlayer from '../../components/videoplayer/VideoPlayer'
import logo from '../../images/sell-landing/moofarm-logo.png'
import Transport from '../../images/sell-landing/transport.png'
import Freedoctor from '../../images/sell-landing/freedoctor.png'
import NasalGuarantee from '../../images/sell-landing/nasal-guarantee.png'
import Injection from '../../images/sell-landing/injection.png'
import { Helmet } from "react-helmet"
import $ from "jquery"


let sellData = [
  { name: "एचएफ", price: "59000", calving: 2, milk: 22, prgenancy: "ताज़ा ब्यायी", url: "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815673882_116816_admin_1_video_WhatsApp%20Video%202022-01-17%20at%206.18.12%20PM.mp4.mp4" },
  { name: "मुर्राह क्रॉस", price: "76000", calving: 2, milk: 9, prgenancy: "ताज़ा ब्यायी", url: "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815673882_118867_admin_1_video_WhatsApp%20Video%202022-01-30%20at%2010.00.38%20AM.mp4.mp4" },
  { name: "मुर्राह", price: "66000", calving: 2, milk: 12, prgenancy: "ताज़ा ब्यायी", url: "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815518066_120375_admin_1_video_WhatsApp%20Video%202022-02-11%20at%204.35.33%20PM.mp4.mp4" },
]

let sellsecondData = [
  { name: "मुर्राह क्रॉस", price: "66000", calving: 1, milk: 9, prgenancy: "ताज़ा ब्यायी", url: "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815673882_121740_admin_1_video_WhatsApp%20Video%202022-02-19%20at%2010.27.42%20AM.mp4.mp4" },
  { name: "मुर्राह क्रॉस", price: "76000", calving: 2, milk: 9, prgenancy: "ताज़ा ब्यायी", url: "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815673882_121743_admin_1_video_WhatsApp%20Video%202022-02-19%20at%209.59.57%20AM.mp4.mp4" },
  { name: "एचएफ", price: "53000", calving: 2, milk: 20, prgenancy: "ताज़ा ब्यायी", url: "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815897512_123535_admin_1_video_WhatsApp%20Video%202022-03-07%20at%2012.25.02%20PM.mp4.mp4" },
]
let sellthirdData = [
  { name: "मुर्राह क्रॉस", price: "66000", calving: 1, milk: 9, prgenancy: "ताज़ा ब्यायी", url: "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815673882_121740_admin_1_video_WhatsApp%20Video%202022-02-19%20at%2010.27.42%20AM.mp4.mp4" },
  { name: "मुर्राह क्रॉस", price: "76000", calving: 2, milk: 9, prgenancy: "ताज़ा ब्यायी", url: "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815673882_121743_admin_1_video_WhatsApp%20Video%202022-02-19%20at%209.59.57%20AM.mp4.mp4" },
  { name: "एचएफ", price: "53000", calving: 2, milk: 20, prgenancy: "ताज़ा ब्यायी", url: "https://res.cloudinary.com/mooofarm/video/upload/c_limit,w_480/4815897512_123535_admin_1_video_WhatsApp%20Video%202022-03-07%20at%2012.25.02%20PM.mp4.mp4" },
]
let utmObj;

class Landing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contactNumber: '',
      showOtpText: false,
      otp: '',
      timer: 60,
      showResendOtp: false
    };
    if (typeof window !== `undefined`)
      window.addEventListener('scroll', this.handleScroll, true);
  }
  fbqTrack = (eventName, params = {}) => {
    if (typeof fbq === 'function') {
      window.fbq("trackCustom", eventName, { ...params });
    }
  }
  componentDidMount() {
    const queryString = window.location.search
    const paramsofquery = new URLSearchParams(queryString);
    if (paramsofquery.get('utm_source') && paramsofquery.get('utm_medium') && paramsofquery.get('utm_campaign') && paramsofquery.get('utm_content')) {
      utmObj = {
        'utm_source': paramsofquery.get('utm_source'),
        'utm_medium': paramsofquery.get('utm_medium'),
        'utm_campaign': paramsofquery.get('utm_campaign'),
        'utm_content': paramsofquery.get('utm_content')
      }
    } else {
      utmObj = {}
    }
    window.analytics.track("Visitors", { utmObj })
  }
  handleScroll = (index) => {
    let that = this;
    for (let i = 0; i < 10; i++) {
      if (that[`myRef${i}`]?.player?.player != undefined) {
        that[`myRef${i}`].player.player.pause();
      }
    }
  }

  handleContactInput = (number) => {
    let contact = number;
    this.setState({
      contactNumber: contact
    })
  }

  handleSendCode = async () => {
    if (this.state.contactNumber === '' || this.state.contactNumber.length !== 10) {
      alert("Please enter 10-digit mobile number");
    } else {
      let payload = {
        "contact": Number(this.state.contactNumber)
      }
      this.fbqTrack("lead_p1", payload);
      window.analytics.track("lead_p1", payload)
      sessionStorage.setItem("CustMobileNumber", this.state.contactNumber)
      const rawResponse = await fetch('https://api.mooofarm.com/v3/api/sell_catalog/visit', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      const content = await rawResponse.json();

      if (content.status === 'success') {

        let payload = {
          "contact": this.state.contactNumber,
          "operand_id": -1
        }
        const rawResponse = await fetch('https://api.mooofarm.com/v3/api/sendOtp', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'appid': 957855
          },
          body: JSON.stringify(payload)
        });
        const content = await rawResponse.json();
        if (content.status === 'success') {
          let timerInterval = setInterval(() => {
            let curTime = this.state.timer
            if (curTime === 1) {
              clearInterval(timerInterval);
              this.setState({
                showResendOtp: true
              })
            }
            this.setState({
              timer: curTime - 1
            })
          }, 1000)
          this.setState({
            showOtpText: true,
          })
        }
      } else {
        alert("Something went wrong. Please try again.")
      }
    }
  }

  handleOtpInput = async (e) => {
    let enteredOtp = e
    this.setState({
      otp: e
    })
    if (enteredOtp.length === 4) {
      let payload = {
        "otp": enteredOtp,
        "contact": this.state.contactNumber
      }
      const rawResponse = await fetch('https://api.mooofarm.com/v3/api/loginWithOtp', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'appid': 957855
        },
        body: JSON.stringify(payload)
      });
      const content = await rawResponse.json();

      if (content.status === 'success') {
        window.location.href = "/WelcomeMoofarm"
      } else {
        alert("OTP did not matched. Please try again.")
      }
    }
  }

  handleReSendOtpCode = async () => {
    this.setState({
      showResendOtp: false,
      timer: 60
    })
    let payload = {
      "contact": this.state.contactNumber,
      "operand_id": -1
    }
    const rawResponse = await fetch('https://api.mooofarm.com/v3/api/sendOtp', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'appid': 957855
      },
      body: JSON.stringify(payload)
    });
    const content = await rawResponse.json();
    if (content.status === 'success') {
      let timerInterval = setInterval(() => {
        let curTime = this.state.timer
        if (curTime === 1) {
          clearInterval(timerInterval);
        }
        this.setState({
          timer: curTime - 1
        })
      }, 1000)
    }
  }

  handleClearModalData = () => {
    this.setState({
      contactNumber: '',
      showOtpText: false
    })
  }

  render() {
    return (
      <div className='main-container'>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700&display=swap"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
          />
          <script src="js/script.js"></script>
          <script src="https://kit.fontawesome.com/dd15047d18.js" crossorigin="anonymous"></script>
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
          <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
        </Helmet>
        <div className="container">
          <div>
            <div className="logo-container">
              <img src={logo} className="moofarm-logo" />
            </div>
          </div>
        </div>
        <div className="container some-voucher">
          <div className="some-voucher-view">
            <img src={Transport} className="free-vocher-img" />
            <p>पशु ट्रांसपोर्ट फ्री</p>
          </div>
          <div className="some-voucher-view">
            <img src={Freedoctor} className="free-vocher-img" />
            <p>डॉक्टर सुविधा  फ्री</p>
          </div>
          <div className="some-voucher-view">
            <img src={NasalGuarantee} className="free-vocher-img" />
            <p>दूध, ब्यात, नस्ल की 100% गारंटी</p>
          </div>
          <div className="some-voucher-view">
            <img src={Injection} className="free-vocher-img" />
            <p>मूपका-खुरपका और लंगड़ा  के टीके लगे हुए </p>
          </div>
        </div>
        <div className="container benifit-mooo-container">
          <div className="row">
            <div className="col-xs-12">
              <h2 className="benifit-mooo-heading">सबसे मुनाफेदार पशु:</h2>
            </div>
            <div className="col-xs-12">
              <div>
                {sellData.map((item, index) => {
                  return <div>
                    <div className="catalog-sell-Slide-video">
                      <div className="sell-video-player">
                        <VideoPlayer
                          key={index}
                          playing={true}
                          refs={(ref) => (this[`myRef${index}`] = ref)}
                          url={item.url} />
                      </div>
                      <div className="Slide-Box-Content">
                        <h3 className="listing-heading">{item.name}</h3>
                        <p className="listing-price">₹{item.price}</p>
                        <div className="row gray-btn-row">
                          <div className="col-xs-4 list-gray-btn-box1">
                            <p className="list-gray-btn">{item.calving} बार ब्याही</p>
                          </div>
                          <div className="col-xs-4 list-gray-btn-box2">
                            <p className="list-gray-btn">दूध {item.milk} लीटर</p>
                          </div>
                          <div className="col-xs-4 list-gray-btn-box3">
                            <p className="list-gray-btn">{item.prgenancy}</p>
                          </div>
                        </div>
                        <div className="row free-btn-row">
                          <div className="col-xs-5 list-green-btn-box1">
                            <p className="list-green-btn">
                              फ्री डिलीवरी{" "}
                              <img
                                src={Delevery}
                                alt="Free"
                                className="list-free-icon-row"
                              />
                            </p>
                          </div>
                          <div className="col-xs-5 list-green-btn-box2">
                            <p className="list-green-btn">
                              टीके लगे हुए{" "}
                              <img
                                src={Syrienge}
                                alt="Free"
                                className="list-free-icon-row"
                              />
                            </p>
                          </div>
                          <div className="col-xs-2 list-gray-btn-box3"></div>
                        </div>
                        <a
                          className="slider-btn"
                          data-toggle="modal"
                          data-target="#myModal"
                        // onClick={() => { this.fbqTrack("LP Buy Initiated") }}
                        >
                          <img src={whitephoneicon} className="phone-icon" />
                          फ्री विजिट बुक करे
                        </a>
                      </div>
                    </div>
                  </div>
                })}
              </div>
            </div>
            <div className="col-xs-12 px-0">
              <div className="catalogsell-banner-container">
                <div className="row">
                  <div className="col-xs-12 text-left">
                    <img src={LandingLogo} alt="Logo" className="main-logo" />
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-xs-12">
                    <p className="catalogsell-banner-heading">
                      डेयरी मुनाफा बढ़ाये, <br></br> मनपसंद पशु सही दाम पर पाए
                    </p>
                    <div
                    // onClick={this.fbqTrack("LP Buy Initiated")}
                    >
                      <a
                        className="catalogsell-banner-btn"
                        data-toggle="modal"
                        data-target="#myModal"
                      // onClick={() => { this.fbqTrack("LP Buy Initiated") }}
                      >
                        फार्म पर आएं - पशु देखें {" "}
                        <img src={ArrowBtn} className="btn-arrow" alt="Arrow Btn" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12">
              {sellsecondData.map((item, index) => {
                return <div>
                  <div className="catalog-sell-Slide-video">
                    <div className="sell-video-player">
                      <VideoPlayer
                        key={index + 3}
                        refs={(ref) => (this[`myRef${index + 3}`] = ref)}
                        url={item.url} />
                    </div>
                    <div className="Slide-Box-Content">
                      <h3 className="listing-heading">{item.name}</h3>
                      <p className="listing-price">₹{item.price}</p>
                      <div className="row gray-btn-row">
                        <div className="col-xs-4 list-gray-btn-box1">
                          <p className="list-gray-btn">{item.calving} बार ब्याही</p>
                        </div>
                        <div className="col-xs-4 list-gray-btn-box2">
                          <p className="list-gray-btn">दूध {item.milk} लीटर</p>
                        </div>
                        <div className="col-xs-4 list-gray-btn-box3">
                          <p className="list-gray-btn">{item.prgenancy}</p>
                        </div>
                      </div>
                      <div className="row free-btn-row">
                        <div className="col-xs-5 list-green-btn-box1">
                          <p className="list-green-btn">
                            फ्री डिलीवरी{" "}
                            <img
                              src={Delevery}
                              alt="Free"
                              className="list-free-icon-row"
                            />
                          </p>
                        </div>
                        <div className="col-xs-5 list-green-btn-box2">
                          <p className="list-green-btn">
                            टीके लगे हुए{" "}
                            <img
                              src={Syrienge}
                              alt="Free"
                              className="list-free-icon-row"
                            />
                          </p>
                        </div>
                        <div className="col-xs-2 list-gray-btn-box3"></div>
                      </div>
                      <a
                        className="slider-btn"
                        data-toggle="modal"
                        data-target="#myModal"
                      // onClick={() => { this.fbqTrack("LP Buy Initiated Sell Landing") }}
                      >
                        <img src={whitephoneicon} className="phone-icon" />
                        फ्री विजिट बुक करे
                      </a>
                    </div>
                  </div>
                </div>
              })}
            </div>
            <div className="col-xs-12">
              {sellthirdData.map((item, index) => {
                return <div>
                  <div className="catalog-sell-Slide-video">
                    <div className="sell-video-player">
                      <VideoPlayer
                        key={index + 6}
                        refs={(ref) => (this[`myRef${index + 6}`] = ref)}
                        url={item.url} />
                    </div>
                    <div className="Slide-Box-Content">
                      <h3 className="listing-heading">{item.name}</h3>
                      <p className="listing-price">₹{item.price}</p>
                      <div className="row gray-btn-row">
                        <div className="col-xs-4 list-gray-btn-box1">
                          <p className="list-gray-btn">{item.calving} बार ब्याही</p>
                        </div>
                        <div className="col-xs-4 list-gray-btn-box2">
                          <p className="list-gray-btn">दूध {item.milk} लीटर</p>
                        </div>
                        <div className="col-xs-4 list-gray-btn-box3">
                          <p className="list-gray-btn">{item.prgenancy}</p>
                        </div>
                      </div>
                      <div className="row free-btn-row">
                        <div className="col-xs-5 list-green-btn-box1">
                          <p className="list-green-btn">
                            फ्री डिलीवरी{" "}
                            <img
                              src={Delevery}
                              alt="Free"
                              className="list-free-icon-row"
                            />
                          </p>
                        </div>
                        <div className="col-xs-5 list-green-btn-box2">
                          <p className="list-green-btn">
                            टीके लगे हुए{" "}
                            <img
                              src={Syrienge}
                              alt="Free"
                              className="list-free-icon-row"
                            />
                          </p>
                        </div>
                        <div className="col-xs-2 list-gray-btn-box3"></div>
                      </div>
                      <a
                        className="slider-btn"
                        data-toggle="modal"
                        data-target="#myModal"
                      // onClick={() => { this.fbqTrack("LP Buy Initiated") }}
                      >
                        <img src={whitephoneicon} className="phone-icon" />
                        फ्री विजिट बुक करे
                      </a>
                    </div>
                  </div>
                </div>
              })}
            </div>
            {/* <div className="col-xs-12">
              <div className="see-then">
                <button>और देखें</button>
              </div>
            </div> */}
            <div className="col-xs-12 px-0">
              <div className="buy-related-container">
                <h3>क्या आप अपने पसंद के पशु नही ढूँढ पाए? <br /> हम आपकी मदद करेंगे 👍</h3>
                <p>पशु खरीद के सम्बंधित और जानकारी के लिए </p>
                <a className="buy-related-btn">
                  <img src={phone} className="phone-icon" />
                  <span>अभी संपर्क करे</span>
                </a>
              </div>
            </div>
            <div className="container sell-popup-button-container">
              <div className="row sell-popup-button-row text-center">
                <div className="col-xs-12">
                  <div className="sell-button-view">
                    <a
                      className="popupbtn"
                      data-toggle="modal"
                      data-target="#myModal"
                    // onClick={() => { this.fbqTrack("LP Buy Initiated") }}
                    >
                      फार्म पर आएं - पशु देखें
                      <img
                        src={ArrowBtn}
                        className="btn-arrow-bottom"
                        alt="Arrow Btn"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="container sell-footer-container">
              <div className="row text-left">
                <div className="col-xs-6 feed-social-background">
                  <img src={LandingLogo} alt="Logo" className="footer-logo" />
                  <div className="sell-social-icon">
                    <a href="https://www.instagram.com/mooofarm/" target="_blank">
                      <img
                        src={sellInstagram}
                        alt="facebook"
                        className="sell-social"
                      />
                    </a>
                    <a href="https://www.facebook.com/mooofarm" target="_blank">
                      <img
                        src={sellFacebook}
                        alt="Instagram"
                        className="sell-social"
                      />
                    </a>
                    <a
                      href="https://in.linkedin.com/company/mooofarm"
                      target="_blank"
                    >
                      <img
                        src={sellLinkedin}
                        alt="Youtube"
                        className="sell-social"
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/c/MooofarmYoutube"
                      target="_blank"
                    >
                      <img
                        src={sellYoutube}
                        alt="Linkedin"
                        className="sell-social"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-xs-6 google-play-button">
                  <p className="google-paly-heading">
                    डाउनलोड करें गूगल प्ले स्टोर से
                  </p>
                  <img src={Rating} alt="Rating" className="rating" />
                  <a
                    href="https://play.google.com/store/apps/details?id=com.moo&hl=en_IN"
                    target="_blank"
                  >
                    <img
                      src={GooglePlay}
                      alt="Google Play"
                      className="google-img"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div id="myModal" class="modal fade" role="dialog">
              <div class="modal-dialog">
                <div class="modal-content sell-catalog-model">
                  <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" onClick={() => this.handleClearModalData()}>
                      &times;
                    </button>
                    <p class="modal-title-view">
                      यहाँ अपना नंबर भरें - हमारी टीम आपसे तुरंत संपर्क करेगी!
                    </p>
                  </div>
                  <div class="modal-body">
                    <div className="row">
                      <div className="col-xs-12 position-relative">
                        <input
                          type="tel"
                          class="tel-input"
                          placeholder="अपना नंबर यहां भरें"
                          name="tel"
                          maxLength={10}
                          value={this.state.contactNumber}
                          onChange={(e) => {
                            e.target.validity.valid ?
                              this.handleContactInput(e.target.value)
                              : alert("Contact number should contain Numbers only ")
                          }}
                          pattern="[0-9]*"
                        />
                        <span className="country-code">+91</span>
                        <div className="input-rightview">
                          {!this.state.showOtpText ? <a
                            className="phone-btn"
                            onClick={() => this.handleSendCode()}

                          >
                            कोड भेजिए
                          </a>
                            : <p>ओ.टी.पी. भेज दिया गया है</p>}
                        </div>
                      </div>
                      <div className="col-xs-12">
                        <p className="save-number-view"><i class="fa-solid fa-lock mr-6"></i> आपका नंबर हमारे पास सुरक्षित है</p>
                      </div>
                      {this.state.showOtpText &&
                        <div className="col-xs-12 position-relative mt-12">
                          <input
                            type="password"
                            class="tel-input-otp custom-mt-0"
                            placeholder="4 अंको का ओ.टी.पी. डालें"
                            name="tel"
                            value={this.state.otp}
                            maxLength={4}
                            onChange={(e) => {
                              e.target.validity.valid ?
                                this.handleOtpInput(e.target.value)
                                : alert("OTP number should contain Numbers only ")
                            }}
                            pattern="[0-9]*"
                          />
                          {this.state.showResendOtp === false ?
                            <div className="input-rightview">
                              <div id="timer">{`00:${this.state.timer}`}</div>
                            </div>
                            :
                            <div className="input-rightview">
                              <button id="timer" onClick={() => { this.handleReSendOtpCode() }}>कोड दोबारा भेजिए</button>
                            </div>
                          }
                        </div>}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Landing
